import React from "react";

import FacettesIcon from "../../images/icons/facettes.svg";
import CBuccaleIcon from "../../images/icons/cbuccale.svg";
import OrthodontieIcon from "../../images/icons/orthodontie.svg";
import PDentaireIcon from "../../images/icons/pdentaire.svg";
import ParodontologieIcon from "../../images/icons/parodontologie.svg";
import ImplantologieIcon from "../../images/icons/implantologie.svg";
import PedodontieIcon from "../../images/icons/pedodontie.svg";
import BFauteuilIcon from "../../images/icons/bfauteuil.svg";
import data from "./data";
import * as styles from "./styles";

const iconsMap = {
  facettes: FacettesIcon,
  cbuccale: CBuccaleIcon,
  orthodontie: OrthodontieIcon,
  pdentaire: PDentaireIcon,
  parodontologie: ParodontologieIcon,
  implantologie: ImplantologieIcon,
  pedodontie: PedodontieIcon,
  bfauteuil: BFauteuilIcon
};

function ServiceIcon({ id }) {
  const TargetIcon = iconsMap[id];

  return <TargetIcon />;
}

export default function Services() {
  return (
    <div id="services" css={styles.services}>
      <section>
        <h2>
          Les <span className="highlight">soins</span> que vous méritez
        </h2>
        <section css={styles.grid}>
          {data.map(service => (
            <div key={service.id} css={styles.service}>
              <ServiceIcon id={service.id} /> <h3>{service.name}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </section>
      </section>
    </div>
  );
}
