import { css } from "@emotion/core";

export const about = css`
  width: 100%;
  background-color: #fff4e8;
`;

export const content = css`
  max-width: 840px;
  margin: 0 auto;
  padding: 0px 24px;
  padding-top: 36px;
  padding-bottom: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  @media only screen and (max-width: 758px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const section = css`
  width: 357px;
  max-width: 100%;
`;

export const imageSection = css`
  ${section};
  position: relative;
  padding-right: 10px;
`;

export const frame = css`
  position: absolute;
  left: 22px;
  bottom: 13px;
  width: calc(100% - 30px);
  stroke: var(--primary);
`;

export const img = css`
  width: 357px;
  position: relative;
  z-index: 2;
  max-width: 100%;
`;
