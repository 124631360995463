import { css } from "@emotion/core";

export const team = css`
  width: 100%;
  background-color: var(--beige);
`;

export const content = css`
  max-width: 908px;
  margin: 0 auto;
  text-align: center;
  padding: 36px 24px;
`;

export const grid = css`
  width: 100%;
  display: grid;
  grid-column-gap: 40px;
`;

export const imageContainer = css`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const frame = css`
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 260px;
`;

export const img = css`
  z-index: 2;
  position: relative;
  transition: transform 0.2s ease-in;
  transform-origin: bottom;
`;

export const buttonMarginButton = css`
  margin-bottom: 16px;
`;

export const buttonsSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  .button:first-of-type {
    margin-bottom: 16px;
  }
`;
