export default [
  {
    id: "fatiha",
    name: "Dr. Fatiha Sbaï",
    title: "Chirurgien Dentaire",
    image:
      "https://res.cloudinary.com/tinloof/image/upload/c_scale,w_250/v1578758155/Sba%C3%AF%20Dentaire/team/team-1_teermn.png",
    description:
      "Diplomée de la faculté dentaire de Rabat, Dr. Sbaï exerce le metier de dentiste depuis plus de 30 ans et a traité des milliers de patients."
  },
  {
    id: "mohamed",
    name: "Dr. Mohamed Zaanouni",
    title: "Orthodontiste",
    image:
      "https://res.cloudinary.com/tinloof/image/upload/c_scale,w_250/v1578757563/Sba%C3%AF%20Dentaire/team/Face-shot-2-1_1_111_fggkb5.png",
    description:
      "Diplomé de la Faculté de Chirurgie Dentaire de Lille, Dr. Zaanouni fait en sorte que tout ses patients aient des dents alignées et un beau sourire."
  },
];
