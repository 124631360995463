import React from "react";
import { css } from "@emotion/core";

import * as styles from "./styles";

export default function Hamburger({ isCross }) {
  return (
    <div css={styles.hamburger}>
      <div
        css={css`
          ${styles.line};
          ${isCross ? styles.cross : ""}
        `}
      />
    </div>
  );
}
