import { css } from "@emotion/core";

export const hamburger = css`
  position: relative;
  height: 16px;
  width: 24px;
`;

export const line = css`
  position: absolute;
  bottom: 7.5px;
  right: 0;
  width: 17px;
  height: 2px;
  border-radius: 3px;
  background-color: var(--black);
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &:before,
  &:after {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    position: absolute;
    right: 0px;
    background-color: var(--black);
    border-radius: 3px;
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &:before {
    opacity: 1;
    top: -7px;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }

  &:after {
    bottom: -7px;
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
`;

export const cross = css`
  width: 24px;
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  &:before {
    opacity: 0;
    top: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }

  &:after {
    transform: rotate(-90deg);
    bottom: 0;
    transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;
