import React from "react";
import PropTypes from "prop-types";
import { Global, css } from "@emotion/core";

import Header from "../Header";
import VerticalNav from "../VerticalNav";
import globalStyles from "./globalStyles";

const links = [
  {
    name: "Soins",
    id: "services"
  },
  {
    name: "L’Équipe",
    id: "team"
  },
  {
    name: "Témoignages",
    id: "reviews"
  },
  {
    name: "Nous contacter",
    id: "contact"
  }
];

export default function Layout({ children }) {
  const [isVerticalNav, setIsVerticalNav] = React.useState(false);

  const toggleVerticalNav = () => setIsVerticalNav(!isVerticalNav);

  return (
    <div
      css={css`
        max-width: 100%;
        overflow-x: hidden;
      `}
    >
      <Global
        styles={css`
          ${globalStyles}
          body {
            ${!isVerticalNav ? `ovferlow-y: auto` : `overflow: hidden;`}
          }
        `}
      />
      <Header {...{ isVerticalNav, toggleVerticalNav, links }} />
      {isVerticalNav && <VerticalNav {...{ toggleVerticalNav, links }} />}
      <main
        css={css`
          padding-top: 94px;
          ${isVerticalNav ? "display: none;" : ""}
        `}
      >
        {children}
      </main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};
