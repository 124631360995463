export default [
  {
    id: "facettes",
    name: "Facettes",
    description:
      "Les facettes sont de fines pellicules en céramique ou en composite qui se collent sur la face visible des dents antérieures pour améliorer leur apparence esthétique."
  },
  {
    id: "cbuccale",
    name: "Chirurgie Buccale",
    description:
      "S'intéresse essentiellement aux maladies de la muqueuse buccale, Infections aiguës, exérèse des kystes et aux extractions des dents de sagesse, incluses ou enclavées. "
  },
  {
    id: "orthodontie",
    name: "Orthodontie",
    description:
      "Traite les malpositions des arcades et des dents aussi bien pour les enfants que pour les adultes. Elle permet l'amélioration de l'esthétique du sourire, un équilibre et une harmonie de l’appareil masticateur."
  },
  {
    id: "pdentaire",
    name: "Prothèse Dentaire",
    description:
      "Destinée à remplacer une dent abîmée ou cassée, une ou plusieurs dents absentes. Elle a  pour objectif de rétablir l'esthétique et la fonction. "
  },
  {
    id: "parodontologie",
    name: "Parodontologie",
    description:
      "S'intéresse aux tissus de soutien de la dent : gencive, cément, os alvéolaire, ligament, et qui peuvent être affectés par deux types de maladies : gingivites et parodontites pouvant aboutir jusqu'à la perte des dents. il est donc important de  les détecter  à un stade précoce."
  },
  {
    id: "implantologie",
    name: "Implantologie",
    description:
      "Un implant est une racine artificielle placée dans l'os alvéolaire pour remplacer une ou plusieurs dents manquantes sans toucher aux dents adjacentes."
  },
  {
    id: "pedodontie",
    name: "Pédodontie",
    description:
      "Discipline consacrée aux enfants, s'intéresse aux soins de caries, aux traitements des traumatismes,  aux pathologies dentaire et des muqueuses, ainsi qu'à la prévention bucco-dentaire. "
  },
  {
    id: "bfauteuil",
    name: "Blanchiment Dentaire",
    description:
      "Technique rapide qui permet , grâce à l’utilisation d’un produit de blanchiment renforcé par les rayons puissants d’une lampe, d'éclaircir la teinte des dents en leur redonnant blancheur et éclat."
  }
];
