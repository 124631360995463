export default [
  {
    id: "taha",
    image:
      "https://res.cloudinary.com/tinloof/image/upload/c_scale,w_90/v1578594873/Sba%C3%AF%20Dentaire/reviews/Taha_Benmoussa_bzjh3z.png",
    name: "Taha Benmoussa",
    description: `Le Dr. Sbaï est très professionnel et très agréable. 

    La prise de rendez-vous est très rapide et les soins apportés sont d'une grande qualité. 
    
    Je recommande à 100% !`,
    stars: 5
  },
  {
    id: "laila",
    image:
      "https://res.cloudinary.com/tinloof/image/upload/c_scale,w_90/v1578594873/Sba%C3%AF%20Dentaire/reviews/Laila_Benseddik_hbb0pq.png",
    name: "Laila Benseddik",
    description: `Je suis venue suite à la recommandation de mon frère. 

    J'ai un aussi beau sourire que le sien désormais !
    `,
    stars: 5
  },
  {
    id: "mamoun",
    image:
      "https://res.cloudinary.com/tinloof/image/upload/c_scale,w_90/v1578594872/Sba%C3%AF%20Dentaire/reviews/Mamoun_Taharraoui_dtrjj7.png",
    name: "Mamoun Taharraoui",
    description: `Superbe expérience, Dr. Sbaï est très qualifiée et profesionnelle !! Je recommande vivement à toutes les personnes qui recherchent un dentiste sur Casablanca. 

    Je recommande vivement !!! 5/5
    `,
    stars: 5
  },
  {
    id: "samia",
    image:
      "https://res.cloudinary.com/tinloof/image/upload/c_scale,w_90/v1578594872/Sba%C3%AF%20Dentaire/reviews/Samia_Moukhalid_p5weou.png",
    name: "Samia Moukhalid",
    description: `Dr Sbai est très humaine. Quand j avais eu une rage de dent, elle a accepté de me voir en urgence pour m’extraire la dent de sagesse une semaine aprés. 

    Une extraction très facile et sans douleurs.`,
    stars: 5
  },
  {
    id: "jade",
    image:
      "https://res.cloudinary.com/tinloof/image/upload/c_scale,w_90/v1578594873/Sba%C3%AF%20Dentaire/reviews/Jade_Sedki_uiigur.png",
    name: "Jade Sedki",
    description: `Service niquel, un docteur attentioné!`,
    stars: 5
  },
  {
    id: "amir",
    image:
      "https://res.cloudinary.com/tinloof/image/upload/c_scale,w_90/v1578594872/Sba%C3%AF%20Dentaire/reviews/Amir_Ghezala_x2j1g7.png",
    name: "Amir Ghezala",
    description: `Excellent dental care, friendly and professional.`,
    stars: 5
  },
  {
    id: "yasmine",
    image:
      "https://res.cloudinary.com/tinloof/image/upload/c_scale,w_90/v1578594873/Sba%C3%AF%20Dentaire/reviews/Yasmine_Attou_sldpsn.png",
    name: "Yasmine Attou",
    description: "Cabinet très propre et service parfait.",
    stars: 5
  },
  {
    id: "yasser",
    image:
      "https://res.cloudinary.com/tinloof/image/upload/c_scale,w_90/v1578594873/Sba%C3%AF%20Dentaire/reviews/Yasser_Al_Banna_ghe6wt.png",
    name: "Yasser Al Banna",
    description: `Great experience at the dentist. Helped me get a complete check after I had pain in my wisdom tooth. 

    Very friendly and extremely helpful throughout the whole process. 
    
    Definitely recommended.
    `,
    stars: 5
  }
];
