import { css } from "@emotion/core";

export const reviews = css`
  width: 100%;
  margin: 0 auto;
  padding: 36px 24px;
`;

export const content = css`
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
`;

export const grid = css`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(284px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const review = css`
  width: 100%;
  width: 284px;
  height: 367px;
  position: relative;
  margin: 0 auto;
`;

export const img = css`
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 96px;
  z-index: 2;
`;

export const card = css`
  height: 322px;
  position: relative;
  top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 54px;
  padding-bottom: 40px;
  z-index: 2;
`;

export const frame = css`
  position: absolute;
  top: 45px;
  left: 0;
  width: 284px;
  height: 322px;
  stroke: var(--beige);
`;

export const score = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 203px;
`;
