import React from "react";

import Frame from "../../images/frame.svg";

import DirectionsIcon from "../../images/icons/directions.svg";


import data from "./data";
import * as styles from "./styles";

function Img({ image, name }) {
  const imgRef = React.useRef();

  return (
    <img src={image} alt={name} ref={imgRef} loading="lazy" css={styles.img} />
  );
}

const DIRECTIONS_LINK =
  "https://www.google.com/maps/dir//centre+dentaire+sbai/@42.6399478,-6.1574736,5z/data=!4m8!4m7!1m0!1m5!1m1!1s0xda7d2c4faa9b4f9:0x8ff2e21d79a98aad!2m2!1d-7.6368595!2d33.5787256";

export default function Team() {
  return (
    <div id="team" css={styles.team}>
      <div css={styles.content}>
      <h2>
          Dentistes formés pour votre <span className="highlight">santé</span>
        </h2>
      <div css={styles.buttonsSection}>
      <a className="button primary" href="tel:+212522236921">
          05 22 23 26 21
        </a>
        <a className="button primary" css={styles.buttonMarginButton} href="tel:+212661520869">
        06 61 52 08 69
        </a>
        <a
              className="button secondary"
              href={DIRECTIONS_LINK}
              target="_blank"
            >
              <DirectionsIcon /> Directions
            </a>
            </div>
        <section css={styles.grid}>
          {data.map(doctor => (
            <section key={doctor.id}>
              <section css={styles.imageContainer}>
                <Frame preserveAspectRatio="none" css={styles.frame} />
                <Img {...doctor} />
              </section>
              <h3>{doctor.name}</h3>
              <p className="large">{doctor.title}</p>
              <p>{doctor.description}</p>
            </section>
          ))}
        </section>
      </div>
    </div>
  );
}
