import React from "react";
import DirectionsIcon from "../../images/icons/directions.svg";


import * as styles from "./styles";

const DIRECTIONS_LINK =
  "https://www.google.com/maps/dir//centre+dentaire+sbai/@42.6399478,-6.1574736,5z/data=!4m8!4m7!1m0!1m5!1m1!1s0xda7d2c4faa9b4f9:0x8ff2e21d79a98aad!2m2!1d-7.6368595!2d33.5787256";

export default function Home() {
  
  return (
    <div id="home" css={styles.home}>
      <section css={styles.textContainer}>
        <section>
          <h1>Un beau sourire à Casablanca</h1>
          <p>Assuré par un soin dentaire de qualité depuis 30 ans</p>
        </section>
        <div css={styles.buttonsSection}>
        <a className="button primary" href="tel:+2120522236921">
          05 22 23 26 21
        </a>
        <a className="button primary" css={styles.buttonMarginButton} href="tel:+212661520869">
        06 61 52 08 69
        </a>
        <a
              className="button secondary"
              href={DIRECTIONS_LINK}
              target="_blank"
            >
              <DirectionsIcon /> Directions
            </a>
            </div>
      </section>
      <div css={styles.imageContainer}>
        <picture>
          <source
            media="(min-width: 480px)"
            srcSet="https://res.cloudinary.com/tinloof/image/upload/v1578788746/Sba%C3%AF%20Dentaire/omar_pjcbx3.jpg"
          />
          <img
            src="https://res.cloudinary.com/tinloof/image/upload/c_scale,w_500/v1578788746/Sba%C3%AF%20Dentaire/omar_pjcbx3.jpg"
            alt="Homepage"
            loading="lazy"
            css={styles.img}
          />
        </picture>
      </div>
    </div>
  );
}
