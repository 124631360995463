import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import Home from "../components/Home";
import About from "../components/About";
import Services from "../components/Services";
import Team from "../components/Team";
import Reviews from "../components/Reviews";
import Contact from "../components/Contact";

const IndexPage = () => (
  <Layout>
    <SEO />
    <Home />
    <About />
    <Services />
    <Team />
    <Reviews />
    <Contact />
  </Layout>
);

export default IndexPage;
