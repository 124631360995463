import React from "react";

import DirectionsIcon from "../../images/icons/directions.svg";
import useInViewport from "../../useInViewport";

import mapStyles from "./mapStyles";
import * as styles from "./styles";

const API_KEY = "AIzaSyDVU6WlWzO_e9gUvyS3cTlJrFnqNw16lxI";
const DIRECTIONS_LINK =
  "https://www.google.com/maps/dir//centre+dentaire+sbai/@42.6399478,-6.1574736,5z/data=!4m8!4m7!1m0!1m5!1m1!1s0xda7d2c4faa9b4f9:0x8ff2e21d79a98aad!2m2!1d-7.6368595!2d33.5787256";

export default function Contact() {
  const mapRef = React.useRef();
  const panoRef = React.useRef();
  const [nodeRef, isVisible] = useInViewport({ threshold: 0 });

  function initialize() {
    const position = { lat: 33.5787, lng: -7.63697 };
    const map = new window.google.maps.Map(mapRef.current, {
      center: position,
      zoom: 14,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      styles: mapStyles
    });
    new window.google.maps.Marker({
      position,
      map: map,
      animation: window.google.maps.Animation.DROP
    });

    const panorama = new window.google.maps.StreetViewPanorama(
      panoRef.current,
      {
        position: position,
        pov: {
          heading: -90,
          pitch: 0
        },
        linksControl: false,
        panControl: false,
        addressControl: false,
        zoomControl: false
      }
    );

    if (window.innerWidth < 480) {
      mapRef.current.style.width = `${window.innerWidth}px`;
      panoRef.current.style.width = `${window.innerWidth}px`;
    }
    map.setStreetView(panorama);
  }

  if (isVisible) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&language=fr`;
    script.addEventListener("load", initialize);
    document.body.appendChild(script);
  }

  return (
    <div id="contact" ref={nodeRef} css={styles.contact}>
      <div css={styles.content}>
        <section css={styles.textContent}>
          <h2>
            Situé au coeur de <span className="highlight">Casablanca</span>
          </h2>
          <section>
            <p className="large highlight-medium">
              8 Boulevard Yacoub El Mansour <br /> Casablanca 20250, Maroc
            </p>
          </section>
          <section>
            <p className="highlight-medium">Lundi - Vendredi</p>
            <p>09:00 - 18:00</p>
          </section>
          <section>
            <p className="highlight-medium">Samedi</p>
            <p>09:00 - 13:00</p>
          </section>
          <section css={styles.buttonsSection}>
            <a className="button primary" href="tel:+212522236921">
              05 22 23 69 21
            </a>
            <a className="button primary" css={styles.buttonMarginButton} href="tel:+212661520869">
        06 61 52 08 69
        </a>
            <a
              className="button secondary"
              href={DIRECTIONS_LINK}
              target="_blank"
            >
              <DirectionsIcon /> Directions
            </a>
          </section>
        </section>
        <section css={styles.mapSection}>
          <div ref={mapRef} css={styles.map}></div>
          <div ref={panoRef} css={styles.map}></div>
        </section>
      </div>
    </div>
  );
}
