import { css } from "@emotion/core";

const serviceWidth = 260;
const colGap = 80;

export const services = css`
  width: 100%;
  max-width: ${serviceWidth * 2 + colGap + 48}px;
  margin: 0 auto;
  text-align: center;
  padding: 0px 24px;
  padding-top: 20px;
`;

export const grid = css`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${serviceWidth}px, 1fr));
  grid-column-gap: ${colGap}px;
  justify-items: center;
`;

export const service = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
  max-width: ${serviceWidth}px;

  @keyframes float {
    0% {
      transform: translateY(3px);
    }
    50% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(3px);
    }
  }

  svg {
    margin-bottom: 16px;
  }

  svg .blue {
    transform-origin: center;
    animation: float 3s ease-in-out infinite;
  }
`;
