import React from "react";

import data from "./data";
import Frame from "../../images/frame.svg";
import * as styles from "./styles";

export default function Reviews() {
  return (
    <div id="reviews" css={styles.reviews}>
      <div css={styles.content}>
        <section>
          <h2>
            Que <span className="highlight">disent</span> nos patients?
          </h2>
          <section css={styles.grid}>
            {data.map(review => (
              <div key={review.id} css={styles.review}>
                <img
                  alt={review.name}
                  src={review.image}
                  loading="lazy"
                  css={styles.img}
                />
                <Frame css={styles.frame} />
                <div css={styles.card}>
                  <p className="highlight">{review.name}</p>
                  <section>{review.description}</section>
                  <section css={styles.score}>
                    <img
                      alt="stars"
                      src="https://res.cloudinary.com/tinloof/image/upload/v1578597134/Sba%C3%AF%20Dentaire/stars_coxczs.png"
                      loading="lazy"
                    />
                    <span className="highlight">sur</span>
                    <img
                      alt="google"
                      src="https://res.cloudinary.com/tinloof/image/upload/v1578597134/Sba%C3%AF%20Dentaire/google_ie5mt1.png"
                      loading="lazy"
                    />
                  </section>
                </div>
              </div>
            ))}
          </section>
        </section>
      </div>
    </div>
  );
}
