import { css } from "@emotion/core";

const globalStyles = css`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

  * {
    box-sizing: border-box;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  html {
    font-family: "Montserrat", sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --primary: #5680e9;
    --black: #040404;
    --gray: #dbd9d2;
    --white: #ffffff;
    --beige: #fff4e8;
    font-size: 14px;
    font-weight: 400;
    color: var(--black);
    position: relative;
    line-height: 1.44;
    position: relative;
  }

  .button,
  input {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
  }

  .button:not(.transparent) {
    width: 159px;
    height: 46px;
    border: 3px solid var(--primary);
    font-size: 14px;
    font-weight: bold;
  }

  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .button svg {
    margin-right: 8px;
  }

  .button.primary {
    color: var(--white);
    background-color: var(--primary);
  }

  .button.secondary {
    color: var(--primary);
    background-color: transparent;
  }

  button.transparent {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    outline: none;
  }

  button:hover,
  a:hover {
    cursor: pointer;
  }
  h1 {
    font-weight: bold;
  }

  h1,
  h2,
  h3 {
    line-height: 1.2;
    margin: 0;
  }

  h1,
  h2 {
    padding-bottom: 12px;
  }

  h2 {
    font-weight: 500;
    line-height: 1.22;
    margin: 0;
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
  }

  a {
    text-decoration: none;
    color: var(--black);
  }

  section {
    padding: 16px 0;
  }

  p {
    margin: 0;
  }

  p.large {
    font-size: 16px;
  }

  h3,
  p {
    padding-bottom: 8px;
  }

  .highlight {
    font-weight: bold;
  }

  .highlight-medium {
    font-weight: 500;
  }

  @media only screen and (max-width: 480px) {
    html {
      font-size: 100%;
    }
    h1 {
      font-size: 32px;
    }
    h2 {
      font-size: 28px;
    }
  }
`;

export default globalStyles;
