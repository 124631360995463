import { css } from "@emotion/core";

export const contact = css`
  width: 100%;
  background-color: var(--beige);
`;

export const buttonMarginButton = css`
  margin-bottom: 16px;
`;

export const content = css`
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
  padding: 36px 24px;
  display: flex;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
`;

export const textContent = css`
  width: 279px;
`;

export const buttonsSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  .button:first-of-type {
    margin-bottom: 16px;
  }
`;

export const mapSection = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const map = css`
  width: 381px;
  height: 234.5px;

  &:first-of-type {
    margin-bottom: 16px;
  }
`;
