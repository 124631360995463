import { css } from "@emotion/core";

export const header = css`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  z-index: 99;
  transition: top 0.5s;
`;

export const nav = css`
  width: 100%;
  max-width: 840px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 24px;
`;

export const links = css`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

export const link = css`
  display: block;
  margin-right: 24px;
  font-weight: 500;
  opacity: 0.7;

  &:hover,
  &:active {
    opacity: 1;
  }
`;

export const hamburgerButton = css`
  display: none;

  @media only screen and (max-width: 480px) {
    display: initial;
  }
`;
