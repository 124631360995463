import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";

import useInViewport from "../../useInViewport";
import Frame from "../../images/frame.svg";
import * as styles from "./styles";

const FRAME_LENGTH = 1603;

export default function About() {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "About_Us_picture_go7ma0" }) {
        childCloudinaryAsset {
          fluid(maxWidth: 357, transformations: "w_357") {
            ...CloudinaryAssetFluid
          }
        }
      }
    }
  `);

  const [nodeRef, isVisible] = useInViewport({ threshold: 0 });

  return (
    <div id="about" css={styles.about}>
      <div css={styles.content}>
        <section css={styles.section}>
          <h2>
            Qui sommes <span className="highlight">nous</span>?
          </h2>
          <section>
            <p className="large">
              Sbaï Dentaire est un centre dentaire qui a soigné des milliers de
              patients depuis son ouverture en 1992 à Casablanca.
            </p>
            <p className="large">
              Dr. Sbaï et son équipe sont à votre écoute, vous promettent un
              soin de qualité et un beau sourire.
            </p>
          </section>
        </section>

        <section css={styles.imageSection} ref={nodeRef}>
          <Img
            alt="Dentiste Fatiha"
            fluid={data.file.childCloudinaryAsset.fluid}
            css={styles.img}
          />
          <Frame
            css={css`
              ${styles.frame};
              stroke-dasharray: ${isVisible ? FRAME_LENGTH : 0};
              stroke-dashoffset: ${isVisible ? 0 : FRAME_LENGTH * 0.75};
              transition: stroke-dashoffset 1.5s linear;
            `}
          />
        </section>
      </div>
    </div>
  );
}
