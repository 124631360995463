import { css } from "@emotion/core";

export const verticalNav = css`
  padding-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const buttonsSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  .button:first-of-type {
    margin-bottom: 16px;
  }
`;
