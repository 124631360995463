import React from "react";

import DirectionsIcon from "../../images/icons/directions.svg";
import * as styles from "./styles";

const DIRECTIONS_LINK =
  "https://www.google.com/maps/dir//centre+dentaire+sbai/@42.6399478,-6.1574736,5z/data=!4m8!4m7!1m0!1m5!1m1!1s0xda7d2c4faa9b4f9:0x8ff2e21d79a98aad!2m2!1d-7.6368595!2d33.5787256";

export default function VerticalNav({ links, toggleVerticalNav }) {
  return (
    <section css={styles.verticalNav}>
      {links
        .filter(link => link.id !== "contact")
        .map(link => (
          <section key={link.id}>
            <a href={`#${link.id}`} onClick={toggleVerticalNav}>
              <h2>{link.name}</h2>
            </a>
          </section>
        ))}
      <section css={styles.buttonsSection}>
        <a className="button primary" href="tel:+212522236921">
          0522 23 69 21
        </a>
        <a className="button secondary" href={DIRECTIONS_LINK} target="_blank">
          <DirectionsIcon /> Directions
        </a>
      </section>
    </section>
  );
}
