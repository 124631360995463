import { css } from "@emotion/core";

const bodyWidth = "840px";

export const buttonsSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  .button:first-of-type {
    margin-bottom: 16px;
  }
`;

export const buttonMarginButton = css`
  margin-bottom: 16px;
`;

export const home = css`
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding-top: 100px;

  @media only screen and (max-width: 1024px) {
    padding-top: 0;
  }
`;

export const textContainer = css`
  width: 448px;
  position: absolute;
  left: calc(50% - ${bodyWidth} / 2);
  padding: 0 24px;
  z-index: 2;

  @media only screen and (max-width: 1024px) {
    text-align: center;
    margin: 0 auto;
    position: relative;
    left: 0;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const imageContainer = css`
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: 849px;

  @media only screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }

  @media only screen and (max-width: 480px) {
    display: block;
    min-height: 472px;
  }

  @media only screen and (max-width: 320px) {
    min-height: 378px;
  }
`;

export const img = css`
  position: relative;
  top: 0px;
  left: calc(50% - ${bodyWidth} / 2 + 290px);
  display: block;

  @media only screen and (max-width: 1024px) {
    left: 0;
  }

  @media only screen and (max-width: 480px) {
    left: calc(50% - 250px);
    width: 500px;
  }

  @media only screen and (max-width: 320px) {
    left: calc(50% - 200px);
    width: 400px;
  }
`;
