import React from "react";
import { Link } from "gatsby";

import Logo from "../../images/logo.svg";
import Hamburger from "../Hamburger";
import * as styles from "./styles";

export default function Header({ links, isVerticalNav, toggleVerticalNav }) {
  return (
    <header css={styles.header}>
      <nav css={styles.nav}>
        <Link to="/">
          <Logo height={18} /> <br />
        </Link>
        <div css={styles.links}>
          {links.map(link => (
            <a css={styles.link} key={link.id} href={`#${link.id}`}>
              {link.name}
            </a>
          ))}
        </div>
        <button
          onClick={toggleVerticalNav}
          title={isVerticalNav ? "close sidebar" : "open sidebar"}
          className="transparent"
          css={styles.hamburgerButton}
        >
          {<Hamburger isCross={isVerticalNav} />}
        </button>
      </nav>
    </header>
  );
}
